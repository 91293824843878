import http from '../http';

/**
 * Booking Api Service
 */
export class BookingService {
  urlPrefix = 'api/listing';
  // activities
  fetchAllActivities() {
    return http.get(`${this.urlPrefix}/activities/`);
  }

  fetchActivities(bookingId) {
    return http.get(`${this.urlPrefix}/activities/${bookingId}`);
  }

  createActivity(data) {
    return http.post(`${this.urlPrefix}/activities/`, data);
  }

  updateActivity(activityId, data) {
    return http.post(`${this.urlPrefix}/activities/${activityId}`, data);
  }

  // showing feedback
  rateShowing(bookingId, data) {
    return http.post(`${this.urlPrefix}/showing-rating/${bookingId}`, data);
  }

  // bookings
  fetchBookings(type = 'landlord') {
    return http.get(`${this.urlPrefix}/bookings?type=${type}`);
  }

  fetchBookingsByProperty(propertyId) {
    return http.get(`${this.urlPrefix}/properties/${propertyId}/bookings`);
  }

  fetchBookingsByPropertyTenant(propertyId) {
    return http.get(`${this.urlPrefix}/properties/${propertyId}/tenant-bookings`);
  }

  fetchTenantBookings() {
    return http.get(`${this.urlPrefix}/fetch-tenant-bookings`);
  }

  fetchBookingById(bookingId) {
    return http.get(`${this.urlPrefix}/bookings/${bookingId}`);
  }

  enquiryToProperty(data) {
    return http.post(`${this.urlPrefix}/enquiry`, data);
  }

  bookingPaymentEmail(bookingId) {
    return http.post(`${this.urlPrefix}/bookings/${bookingId}/payment-email`);
  }

  createBooking(data) {
    return http.post(`${this.urlPrefix}/bookings`, data);
  }

  createCustomBooking(data) {
    return http.post(`${this.urlPrefix}/bookings/custom`, data);
  }

  updateBooking(bookingId, data) {
    return http.post(`${this.urlPrefix}/bookings/${bookingId}`, data);
  }

  assignBooking(bookingId, data) {
    return http.post(`${this.urlPrefix}/bookings/assign/${bookingId}`, data);
  }

  deleteBooking(bookingId) {
    return http.delete(`${this.urlPrefix}/bookings/${bookingId}/delete`);
  }

  submitBookingApplication(bookingId, data) {
    return http.post(`${this.urlPrefix}/submit-booking-application/${bookingId}`, data)
  }

  getBookingFileUrl(data) {
    return http.get(`${this.urlPrefix}/get-booking-file-url`, {params: data})
  }
}

export const BookingApi = new BookingService();
