const initialState = {
  loading: false,
  error: false,
};

function appReducer(state = initialState) {
  return state;
}

export default appReducer;
