import { createContext, useReducer, useEffect, useState } from 'react';
import { BookingApi } from 'services/api/booking';
import { orderBy, cloneDeep } from 'lodash';
import { useUser } from 'contexts/UserContext';
import { Alert } from 'rsuite';
import { useErrorTracker } from 'utils/use-error-tracker';

const initialState = {
  loading: true,
  error: '',
  data: [],
};

const reducer = (bookingsState, action) => {
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return {
        loading: false,
        error: '',
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        loading: false,
        error: 'There was an error fetching data.',
        data: [],
      };
    case 'CLEAR_DATA':
      // console.log('cleardata')
      return initialState;
    case 'UPDATE':
      const newBookingsState = cloneDeep(bookingsState);
      const idToEdit = newBookingsState.data.findIndex(booking => booking.id === action.payload.id);
      newBookingsState.data[idToEdit] = action.payload;
      return {
        loading: false,
        error: '',
        data: orderBy(newBookingsState.data, ['updatedAt'], ['desc']),
      };
    case 'ADD_ENQUIRY':
      const currentBookingsState = cloneDeep(bookingsState);
      currentBookingsState.data.push(action.payload);
      return {
        loading: false,
        error: '',
        data: orderBy(currentBookingsState.data, ['updatedAt'], ['desc']),
      };
    case 'DELETE':
      const oldBookingsState = cloneDeep(bookingsState);
      const idToRemove = oldBookingsState.data.findIndex(booking => booking.id === action.payload);
      oldBookingsState.data.splice(idToRemove, 1);
      return {
        loading: false,
        error: '',
        data: orderBy(oldBookingsState.data, ['updatedAt'], ['desc']),
      };
    case 'ARCHIVE':
      return { ...bookingsState, data: bookingsState.data.filter(p => p.id !== action.payload) };
    default:
      return bookingsState;
  }
};

export const BookingsContext = createContext(initialState);

export const BookingsContextProvider = ({ children }) => {
  const errorTracker = useErrorTracker();
  const [bookingsState, bookingsDispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState(undefined);
  const getUser = useUser();

  // console.log(getUser);
  useEffect(() => {
    if (getUser?.user?.userType === 'landlord') {
      // console.log("found user", getUser.user.userType);
      user !== getUser && setUser(getUser.user);
    }
  }, [getUser]);
  useEffect(() => {
    if (user) {
      bookingsDispatch({ type: 'CLEAR_DATA' });
      BookingApi.fetchBookings('landlord')
        .then(res => {
          bookingsDispatch({ type: 'FETCH_SUCCESS', payload: orderBy(res.data, ['updatedAt'], ['desc']) });
        })
        .catch(err => {
          bookingsDispatch({ type: 'FETCH_FAILURE' });
          errorTracker.error('Fetch bookings (bookings context) error');
          Alert.error('Something went wrong while fetching bookings.', 5000);
          console.error(err);
        });
    }
  }, [user]);

  return (
    <BookingsContext.Provider value={{ state: bookingsState, dispatch: bookingsDispatch }}>
      {children}
    </BookingsContext.Provider>
  );
};
