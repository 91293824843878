import React, { createContext, useReducer, useEffect, useState } from 'react';
import { UserApi } from 'services/api/user';
import { useUser } from 'contexts/UserContext';

const initialState = {
  loading: true,
  error: '',
  data: [],
  refreshTimestamp: new Date().getTime(),
};

const reducer = (tableAccessState, action) => {
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return { ...tableAccessState, loading: false, error: '', data: action.payload };
    case 'FETCH_FAILURE':
      return { ...tableAccessState, loading: false, error: 'There was an error fetching data.', data: {} };
    case 'REFRESH_DATA':
      return { ...tableAccessState, loading: true, refreshTimestamp: new Date().getTime() };
    case 'CLEAR_DATA':
      return initialState;
    default:
      return tableAccessState;
  }
};

export const TableAccessContext = createContext(initialState);

export const TableAccessContextProvider = ({ children }) => {
  const [tableAccessState, tableAccessDispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState(undefined);
  const getUser = useUser();

  useEffect(() => {
    if (getUser?.user?.userType === 'landlord') {
      user !== getUser && setUser(getUser.user);
    }
  }, [getUser]);

  useEffect(() => {
    if (user) {
      tableAccessDispatch({ type: 'CLEAR_DATA' });
      tableAccessDispatch({ ...tableAccessState, loading: true });
      UserApi.getMyTableAccess()
        .then(res => tableAccessDispatch({ ...tableAccessState, type: 'FETCH_SUCCESS', payload: res.data }))
        .catch(err => tableAccessDispatch({ ...tableAccessState, type: 'FETCH_FAILURE', error: err }));
    }
  }, [user, tableAccessState.refreshTimestamp]);

  return (
    <TableAccessContext.Provider value={{ state: tableAccessState, dispatch: tableAccessDispatch }}>{children}</TableAccessContext.Provider>
  );
};