import '../styles/main.css';
import '../styles/tailwind.css';
import React from 'react';

import { Provider } from 'react-redux';
import Head from 'next/head';
import App from 'next/app';

import withReduxStore from 'utils/with-redux-store';
import { appWithTranslation } from 'utils/with-i18next';
import { ErrorTrackerProvider } from 'utils/use-error-tracker';

import 'fontsource-metropolis';
import '@typefaces-pack/typeface-inter';
import TagManager from 'react-gtm-module';
import { PropertiesContextProvider } from 'contexts/PropertiesContext';
import { BuildingContextProvider } from 'contexts/BuildingContext';
import { BookingsContextProvider } from 'contexts/BookingsContext';
import { TicketContextProvider } from 'contexts/TicketContext';
import { UserContextProvider } from 'contexts/UserContext';
import { ActivitiesContextProvider } from 'contexts/ActivitiesContext';
import { TableAccessContextProvider } from 'contexts/TableAccessContext';

const tagManagerArgs = {
  id: 'GTM-NHZJKL8',
};

class Srr extends App {
  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize(tagManagerArgs);
    }
            // Intercom code snippet
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/jxutt9op';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }
  render() {
    const { Component, pageProps, reduxStore } = this.props;
    const getLayout = Component.getLayout || (page => page);

    return (
      <>
        <Head>
          <title>Data Intelligence Software for Real Estate | Leni</title>
        </Head>
        <ErrorTrackerProvider>
          <UserContextProvider>
            <BuildingContextProvider>
              <PropertiesContextProvider>
                <BookingsContextProvider>
                  <TicketContextProvider>
                    <ActivitiesContextProvider>
                      <TableAccessContextProvider>
                        <Provider store={reduxStore}>{getLayout(<Component {...pageProps}></Component>)}</Provider>
                      </TableAccessContextProvider>
                    </ActivitiesContextProvider>
                  </TicketContextProvider>
                </BookingsContextProvider>
              </PropertiesContextProvider>
            </BuildingContextProvider>
          </UserContextProvider>
        </ErrorTrackerProvider>
      </>
    );
  }
}

export default appWithTranslation(withReduxStore(Srr));
