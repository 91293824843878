import http from '../http';

/**
 * Building Api Service
 */
export class BuildingService {
  urlPrefix = 'api/listing';

  getBuildingsById(data) {
    return http.post(`${this.urlPrefix}/buildings`, data);
  }

  getPropertiesByBuildingName(data) {
    return http.post(`${this.urlPrefix}/properties-by-building-name`, data);
  }

  getPropertiesByBuildingId(data) {
    return http.post(`${this.urlPrefix}/properties-by-building-id`, data);
  }

  getBuildingInstances() {
    return http.get(`${this.urlPrefix}/building-instances`);
  }

  updateBuildingInstance(buildingId, updateData) {
    return http.put(`${this.urlPrefix}/building-instances/${buildingId}`, updateData);
  }
}

export const BuildingApi = new BuildingService();
