import React, { useContext, useState, useEffect, createContext } from 'react';
import { Router } from 'utils/with-i18next';
import http from '../services/http';
import { getDefaultRedirectPage } from 'utils/getDefaultRedirectPage';
import { useSetUserInfoOnErrorTracker, useErrorTracker } from 'utils/use-error-tracker';

const UserDataContext = createContext();

const fetchUser = () => {
  return fetch('/api/users/me')
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null; // There is probably an error fetching the user.
      }
    })
    .catch(err => console.error(err));
};



export function useUser({ redirectTo = false, redirectIfFound = false } = {}) {
  const userDataContext = useContext(UserDataContext);

  useEffect(() => {
    if (userDataContext && !userDataContext.userDataContextBeingInitialized) {
      const user = userDataContext.user;

      const defaultRedirectPage = getDefaultRedirectPage(user, redirectTo);
      if (user && user.userType === 'landlord') {
        // Call boot method for intercom when user changes
        window.Intercom('boot', {
        app_id: 'jxutt9op',
        user_id: user?.id ? user.id : null, // User ID
        name: user?.firstName ? user.firstName : null, // Full name
        email: user?.email? user.email : null, // the email for your user
        company: user?.organisationId ? user.organisationId : null, // the company name
        });
      } else {
        window.Intercom('shutdown');
      }

      if (
        // If redirectTo is set, redirect if the user was not found.
        (redirectTo && !redirectIfFound && !user?.email) ||
        // If redirectIfFound is also set, redirect if the user was found
        (redirectIfFound && user?.email)
      ) {
        Router?.push(defaultRedirectPage);
      }
    }
  }, [userDataContext, redirectTo, redirectIfFound]);
  return userDataContext;
}

export function UserContextProvider({ children }) {
  //Initializing the state
  const errorTracker = useErrorTracker();
  const [user, setUser] = useState(null);
  const [userDataContextBeingInitialized, setUserDataContextBeingInitialized] = useState(true);
  useSetUserInfoOnErrorTracker(user);

  //Function to update the user data after fetching the user information from the backend
  const mutateUser = responseData => {
    setUser(responseData?.data);
  };

  const refreshUser = () => {
    fetchUser().then(user => {
      setUser(user);
      setUserDataContextBeingInitialized(false);
    });
  };

  const clearUserIfFetchingUserFails = () => {
    return fetch('/api/users/me').then(res => {
      if (res.status === 401) {
        clearUser();
      }
    });
  };

  const clearUser = () => {
    setUser(null);
  };

  const isUserLoggedIn = () => {
    return !!user;
  };

  //When the context gets created, we try to fetch the user information and save it in the context to be used accosss the app
  //until there is an 401 error that get caught which means the login access token has expired or the user has manually logged out
  //by using the mutateUser function to clear the user data
  useEffect(() => {
    refreshUser();

    http.interceptors.response.use(
      function(response) {
        // return the response as is since all we care about is intercepting 401 status code
        return response;
      },
      function(error) {
        if (error && error.response && error.response.status === 401) {
          //If a 401 error is caught this means that the access token has expired so we should the user info
          clearUserIfFetchingUserFails();
        }

         if (error && error.response && error.response.status >= 500) {
          //If a 500 error is caught then log the error
          errorTracker.critical("5XX HTTP Error was intercepted. URL="+error?.config?.url, error, error?.response);
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <UserDataContext.Provider
      value={{ userDataContextBeingInitialized, user, mutateUser, refreshUser, clearUser, isUserLoggedIn }}>
      {children}
    </UserDataContext.Provider>
  );
}
