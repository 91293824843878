import { createContext, useReducer, useEffect, useState } from 'react';
import { Alert } from 'rsuite';
import { PropertyApi } from 'services/api/property';
import { cloneDeep } from 'lodash';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { useUser } from 'contexts/UserContext';
import { format } from 'date-fns';
import { useErrorTracker } from 'utils/use-error-tracker';

const dateWithin7Days = dateType => {
  return (
    differenceInCalendarDays(new Date(dateType), new Date()) <= 7 &&
    differenceInCalendarDays(new Date(dateType), new Date()) > -1
  );
};

const initialState = {
  loading: true,
  error: '',
  data: [],
  idToBuildingInfoMap: {},
};

const getIdToBuildingInfoMap = buildingArray => {
  const idToBuildingInfo = {};
  if (buildingArray) {
    buildingArray.map(b => {
      idToBuildingInfo[b.id] = b;
    });
  }

  return idToBuildingInfo;
};

const reducer = (propertiesState, action) => {
  const propertiesStateCopy = cloneDeep(propertiesState);
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return {
        loading: false,
        error: '',
        data: action.payload,
        idToBuildingInfoMap: getIdToBuildingInfoMap(action.payload),
      };
    case 'FETCH_FAILURE':
      return {
        loading: false,
        error: 'There was an error fetching data.',
        data: [],
        idToBuildingInfoMap: {},
      };
    case 'CLEAR_DATA':
      return initialState;
    case 'ADD_PROPERTY':
      // console.log('addproperty', action.payload)
      const newProperties = [...propertiesState.data, { ...action.payload }];
      propertiesStateCopy.data = newProperties;
      propertiesStateCopy.idToBuildingInfo = getIdToBuildingInfoMap(propertiesStateCopy.data);
      return propertiesStateCopy;
    case 'UPDATE_PROPERTY':
      const indexToEdit = propertiesState.data.findIndex(prop => prop.id === action.payload.id);
      propertiesStateCopy.data[indexToEdit] = action.payload;
      return propertiesStateCopy;
    case 'ADD_TENANT':
      const propToEdit = propertiesState.data.findIndex(prop => prop.id === action.payload.property.id);
      const rmToEdit = propertiesState.data[propToEdit].rooms.findIndex(room => room.id === action.payload.room.id);
      const newTenant = {
        ...action.payload.tenant,
        name: action.payload.tenant.firstName + ' ' + action.payload.tenant.lastName,
        moveIn: format(new Date(action.payload.tenant.moveIn), 'yyyy-MM-dd'),
        moveOut: format(new Date(action.payload.tenant.moveOut), 'yyyy-MM-dd'),
        movingInWithin7Days: dateWithin7Days(action.payload.tenant.moveIn),
        movingOutWithin7Days: dateWithin7Days(action.payload.tenant.moveOut),
      };
      if (rmToEdit !== -1) {
        propertiesStateCopy.data[propToEdit].rooms[rmToEdit].tenants.push(newTenant);
      } else {
        propertiesStateCopy.data[propToEdit].rooms.push({ ...action.payload.room, tenants: [newTenant] });
      }
      propertiesStateCopy.idToBuildingInfo = getIdToBuildingInfoMap(propertiesStateCopy.data);
      return propertiesStateCopy;
    case 'UPDATE_TENANT':
      const propertyToEdit = propertiesState.data.findIndex(prop => prop.id === action.payload.property.id);
      const roomToEdit = propertiesState.data[propertyToEdit].rooms.findIndex(
        room => room.id === action.payload.tenant.room.id
      );
      const tenantToEdit = propertiesState.data[propertyToEdit].rooms[roomToEdit].tenants.findIndex(
        tenant => tenant.id === action.payload.tenant.id
      );
      const updatedTenant = {
        ...action.payload.tenant,
        name: action.payload.tenant.firstName + ' ' + action.payload.tenant.lastName,
        movingInWithin7Days: dateWithin7Days(action.payload.tenant.moveIn),
        movingOutWithin7Days: dateWithin7Days(action.payload.tenant.moveOut),
      };
      propertiesStateCopy.data[propertyToEdit].rooms[roomToEdit].tenants[tenantToEdit] = updatedTenant;
      propertiesStateCopy.idToBuildingInfo = getIdToBuildingInfoMap(propertiesStateCopy.data);
      return propertiesStateCopy;
    case 'ARCHIVE_PROPERTY':
      return { ...propertiesState, data: propertiesState.data.filter(p => p.id !== action.payload) };
    // This case updates pricingSetting attribute of ALL properties that share the same buildingId at the setting that is updated
    case 'UPDATE_PRICING_SETTINGS':
      let updatedProperties = propertiesState.data.map(property => {
        if (property.buildingId === action.payload.buildingId) {
          return { ...property, pricingSetting: action.payload };
        }
        return property;
      });
      return { ...propertiesState, data: updatedProperties };
    // This case updates the property's renewals attribute whenever renewals is updated based on propertyId. Note that payload is always an array of renewals.
    case 'UPDATE_RENEWALS':
      let propertiesWithUpdatedRenewals = propertiesState.data.map(property => {
        let updatedRenewals = [];
        for (let renewal of action.payload) {
          if (property.id === renewal.propertyId) {
            updatedRenewals.push(renewal);
          }
        }
        return { ...property, renewals: updatedRenewals };
      });
      return { ...propertiesState, data: propertiesWithUpdatedRenewals };
    default:
      return propertiesState;
  }
};

export const PropertiesContext = createContext(initialState);

export const PropertiesContextProvider = ({ children }) => {
  const errorTracker = useErrorTracker();
  const [propertiesState, propertiesDispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState(undefined);
  const getUser = useUser();

  useEffect(() => {
    if (getUser?.user?.userType === 'landlord') {
      // console.log("found user", getUser.user.userType);
      user !== getUser && setUser(getUser.user);
    }
  }, [getUser]);
  useEffect(() => {
    if (user) {
      propertiesDispatch({ type: 'CLEAR_DATA' });
      // console.log('New Fetch - getProperties')
      PropertyApi.getProperties()
        .then(res => {
          propertiesDispatch({ type: 'FETCH_SUCCESS', payload: res.data });
        })
        .catch(err => {
          propertiesDispatch({ type: 'FETCH_FAILURE' });
          errorTracker.error('Get properties (properties context) error');
          Alert.error('Something went wrong while fetching properties.', 5000);
          console.error(err);
        });
    }
  }, [user]);

  return (
    <PropertiesContext.Provider value={{ state: propertiesState, dispatch: propertiesDispatch }}>
      {children}
    </PropertiesContext.Provider>
  );
};
